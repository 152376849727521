export const formatDateWithTime = (date) => {
  const d = new Date(parseInt(date));
  const options = {
    timeZone: "America/Los_Angeles",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDate = d.toLocaleString("en-US", options);
  return formattedDate;
};
