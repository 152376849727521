import TicketsApi from "api/tickets";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DropdownReports from "components/DropdownReport";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import React, { useState, useEffect } from "react";
// Custom styles for the Tables
import styles from "layouts/tables/styles";
// import SuiInput from "components/SuiInput/index";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import { TimePicker } from "antd";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import { get_LineChart_data, generate_bar_chart_Data } from "./functions.js";
import moment from "moment";
// import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Spin } from "antd";
const Spinner = () => <Spin tip="Fetching Tickets..." size="large"></Spin>;
function Tables_All() {
  const classes = styles();
  let [rowsDB, setRowsDB] = useState([]);
  const [is_fetching_data, set_fetching_data] = useState(false);
  let [customizeReportByActivity, setCustomizeByActivity] = useState("Total Reservations");
  let [customizeReportByIdentifier, setCustomizeByIdentifier] = useState("Tag Name");
  let [tot_reservations_by_tag_CHART_DATA, setTot_reservations_by_tag_CHART_DATA] = useState({
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "My First Dataset",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  });
  let [percentageChartData, set_percentageChartData] = useState({
    labels: ["16-20", "21-25", "26-30", "31-36", "36-42", "42+"],
    datasets: [
      {
        label: "Percentage",
        color: "dark",
        data: [15, 20, 12, 60, 20, 15],
      },
    ],
  });
  let [percentageChartData_by_pullerName, set_percentageChartData_by_pullerName] = useState({});
  // let [doughnut_data_Tot_reserv_by_tag, set_doughnut_data_Tot_reserv_by_tag] = useState({
  //   labels: ["Test", "Github", "Bootsnipp", "Dev.to", "Codeinwp"],
  //   datasets: [
  //     {
  //       label: "Total Reservations",
  //       backgroundColors: ["info", "dark", "error", "secondary", "primary"],
  //       data: [15, 20, 12, 60, 20],
  //     },
  //   ],
  // });
  const [date, setDate] = useState(undefined);
  const [fromTime, setFromTime] = useState(undefined);
  const [toTime, setToTime] = useState(undefined);
  useEffect(async () => {
    console.log("🎈customize reports by changed");
    console.log(customizeReportByActivity);
    if (customizeReportByActivity != "Total Reservations") {
      //if not first render
      let barChart_data_by_tagName = await generate_bar_chart_Data(
        tot_reservations_by_tag_CHART_DATA.datasets,
        customizeReportByActivity
      );
      let barChart_data_by_pullerName = await generate_bar_chart_Data(
        tot_reservations_by_tag_CHART_DATA.puller_name_dataset.datasets,
        customizeReportByActivity
      );
      console.log("percentageChartData_by_pullerName");
      console.log(percentageChartData_by_pullerName);
      console.log("barChart_data_by_tagName");
      console.log(barChart_data_by_tagName);
      // set_doughnut_data_Tot_reserv_by_tag(doughnut_data);
      set_percentageChartData(barChart_data_by_tagName);
      set_percentageChartData_by_pullerName(barChart_data_by_pullerName);
    }
  }, [customizeReportByActivity]);
  //add table useRef
  // const { columns } = confirmedTicketsTableData;
  //const { columns: prCols, rows: prRows } = projectsTableData;

  useEffect(async () => {
    set_fetching_data(true);
    TicketsApi.getAllTickets_ViewSet(1).then(async (allTickets) => {
      let sortTickets = () =>
        allTickets.data.sort(function (x, y) {
          return parseInt(y.reserved_at) - parseInt(x.reserved_at);
        });
      setRowsDB(allTickets.data.status != "error" ? sortTickets() : []);
      set_fetching_data(false);
    });
  }, []);

  // date range filter

  const downloadPageAsPDF = () => {
    // Capture the entire page as a canvas
    html2canvas(document.body).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Create a new jsPDF instance
      const pdf = new jsPDF("p", "mm", "a4");

      // Calculate the number of pages needed
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      // Add image to PDF
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Save the PDF
      pdf.save("Reports-TixRelay.pdf");
    });
  };
  const disabledDate = (date) => {
    const currentDate = new Date();
    return date > currentDate;
  };

  const callApi = async () => {
    if (date !== null && date !== undefined) {
      console.log("⭐callApi", date);
      set_fetching_data(true);
      let from = moment(date[0]).format("YYYY-MM-DD");
      let to = moment(date[1]).format("YYYY-MM-DD");
      if (fromTime && toTime) {
        console.log("Time exists", fromTime);
        from = moment(date[0]);
        to = moment(date[1]);
        from = from
          .set({
            hour: fromTime.$H,
            minute: fromTime.$m,
            second: 0,
          })
          .format("YYYY-MM-DD HH:mm:ss");
        to = to
          .set({
            hour: toTime.$H,
            minute: toTime.$m,
            second: 0,
          })
          .format("YYYY-MM-DD HH:mm:ss");
      }
      TicketsApi.getFilteredAllTickets(from, to).then(async (allTickets) => {
        setRowsDB(allTickets.data.status != "error" ? allTickets.data : []);
        // console.log("🎈API response: ");
        // console.log(allTickets.data);
        set_fetching_data(false);
      });
    }
  };
  const handleFromTimeChange = (time) => {
    console.log("handleFromTimeChange", time);
    setFromTime(time);
  };
  const handleToTimeChange = (time) => {
    console.log("handleToTimeChange", time);
    setToTime(time);
  };
  useEffect(() => {
    callApi();
  }, [fromTime, toTime, date]);

  useEffect(async () => {
    if (rowsDB.length == 0) return;
    console.log("🎈rowsDB changed. Labels:");
    let chart_data = get_LineChart_data(rowsDB);
    // let doughnut_data = generateDoughnutData(chart_data.datasets);
    // let doughnut_data_by_category = generateDoughnutData(chart_data.by_category_dataset.datasets);
    // doughnut_data.by_category_dataset = doughnut_data_by_category;
    console.log("chart_data");
    console.log(chart_data);
    let barChart_data = await generate_bar_chart_Data(chart_data.datasets);
    let barChart_data_by_pullerName = await generate_bar_chart_Data(
      chart_data.puller_name_dataset.datasets
    );
    console.log("percentageChartData_by_pullerName");
    console.log(percentageChartData_by_pullerName);
    console.log("percentage_data");
    console.log(barChart_data);
    setTot_reservations_by_tag_CHART_DATA(chart_data);
    // set_doughnut_data_Tot_reserv_by_tag(doughnut_data);
    set_percentageChartData(barChart_data);
    set_percentageChartData_by_pullerName(barChart_data_by_pullerName);
  }, [rowsDB]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3} id="reports-container">
          <Card>
            <SuiBox
              id="date-range-breakdown-container"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SuiTypography variant="h6">Reports</SuiTypography>
              {is_fetching_data ? <Spinner /> : null}
              <DropdownReports
                customizeReportByIdentifier={customizeReportByIdentifier}
                customizeReportBy={customizeReportByActivity}
                setCustomizeReportBy={setCustomizeByActivity}
                setCustomizeByIdentifier={setCustomizeByIdentifier}
              />
              <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                textAlign="center"
                p={3}
              >
                <SuiBox pr={1}>
                  <label htmlFor="dateRangePicker">Date Range</label>
                  <DateRangePicker
                    value={date}
                    id="dateRangePicker"
                    onChange={setDate}
                    size="md"
                    placeholder="Select Date Range"
                    style={{ width: 260, display: "block", margin: "auto" }}
                    placement="bottomEnd"
                    disabledDate={disabledDate}
                  />
                  <SuiBox display="flex" alignItems="center" marginTop="5px">
                    <TimePicker
                      onChange={handleFromTimeChange}
                      format="HH:mm"
                      use12Hours
                      placeholder="From Time"
                    />
                    <span style={{ margin: "0 10px" }}>to</span>
                    <TimePicker
                      onChange={handleToTimeChange}
                      format="HH:mm"
                      use12Hours
                      placeholder="To Time"
                    />
                  </SuiBox>
                </SuiBox>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  style={{ marginRight: "5px" }}
                  disableElevation
                  onClick={() => downloadPageAsPDF()}
                  endIcon={<DownloadIcon />}
                >
                  Download PDF
                </Button>
              </SuiBox>
            </SuiBox>
            <SuiBox id="container-lineChart" customClass={classes.tables_table}>
              <VerticalBarChart
                title={
                  "Y-axis: " +
                  (customizeReportByActivity == "Total Reservations"
                    ? "All"
                    : customizeReportByActivity) +
                  " Reservations " +
                  " - X-axis: " +
                  customizeReportByIdentifier
                }
                chart={
                  customizeReportByIdentifier == "Puller Name"
                    ? percentageChartData_by_pullerName
                    : percentageChartData
                }
                height="50vh"
                customizeReportBy={customizeReportByActivity}
                customizeReportByIdentifier={customizeReportByIdentifier}
              />
            </SuiBox>
            <SuiBox id="container-doughnuts" display="flex" width="100%">
              {/* <SuiBox id="container-doughnut" width="30%">
                <DefaultDoughnutChart
                  title={
                    "Top 5 " + customizeReportByIdentifier + " by " + customizeReportByActivity
                  }
                  chart={doughnut_data_Tot_reserv_by_tag}
                  customizeReportBy={customizeReportByActivity}
                  customizeReportByIdentifier={customizeReportByIdentifier}
                />
              </SuiBox> */}
              <SuiBox id="container-doughnut" width="100%">
                <DefaultLineChart
                  title={
                    "Line Chart " + customizeReportByActivity + " by " + customizeReportByIdentifier
                  }
                  chart={{
                    labels: tot_reservations_by_tag_CHART_DATA.labels,
                    datasets: tot_reservations_by_tag_CHART_DATA.datasets,
                    by_category_dataset: tot_reservations_by_tag_CHART_DATA.puller_name_dataset,
                  }}
                  customizeReportBy={customizeReportByActivity}
                  customizeReportByIdentifier={customizeReportByIdentifier}
                  height="50vh"
                />
              </SuiBox>
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables_All;
