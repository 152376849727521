import React, { useState } from "react";
import { Button, Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { message } from "antd";
import PropTypes from "prop-types";

function DropdownReports({
  customizeReportBy,
  customizeReportByIdentifier,
  setCustomizeReportBy,
  setCustomizeByIdentifier,
}) {
  const [loadings, setLoadings] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const handleItemClick = (label, isCategory) => {
    const setCustomizeFunction = isCategory ? setCustomizeReportBy : setCustomizeByIdentifier;
    const setLoader = isCategory ? setLoadingCategory : setLoadings;
    if (customizeReportBy === label && isCategory) {
      message.info(`Customizing report by ${label} already selected.`);
    } else if (customizeReportByIdentifier === label && !isCategory) {
      message.info(`Customizing report by ${label} already selected.`);
    } else {
      setLoader(true);
      message.info(`Customizing report to ${label}...`);
      setCustomizeFunction(label);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const categoryMenu = (
    <Menu onClick={({ key }) => handleItemClick(key, true)}>
      <Menu.Item key="All">All</Menu.Item>
      <Menu.Item key="Onsale">Onsale</Menu.Item>
      <Menu.Item key="Postsale">Postsale</Menu.Item>
      <Menu.Item key="Drop">Drop</Menu.Item>
    </Menu>
  );

  const identifierMenu = (
    <Menu onClick={({ key }) => handleItemClick(key, false)}>
      <Menu.Item key="Tag Name">Tag Name</Menu.Item>
      <Menu.Item key="Puller Name">Puller Name</Menu.Item>
    </Menu>
  );

  return (
    <Space direction="vertical">
      <Dropdown overlay={categoryMenu} trigger={["click"]}>
        <Button loading={loadingCategory}>
          <Space>
            Y-Axis: {customizeReportBy}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Dropdown overlay={identifierMenu} trigger={["click"]}>
        <Button loading={loadings}>
          <Space>
            X-Axis: {customizeReportByIdentifier}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </Space>
  );
}

DropdownReports.propTypes = {
  customizeReportBy: PropTypes.string,
  customizeReportByIdentifier: PropTypes.string,
  setCustomizeReportBy: PropTypes.func,
  setCustomizeByIdentifier: PropTypes.func,
};

export default DropdownReports;
