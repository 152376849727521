// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_title  {
    color: navy;
    font-size: initial;
}
.notification_message {
    font-size: medium;
    font-family: -webkit-body;
}`, "",{"version":3,"sources":["webpack://./src/examples/Navbars/DashboardNavbar/notficationStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".notification_title  {\r\n    color: navy;\r\n    font-size: initial;\r\n}\r\n.notification_message {\r\n    font-size: medium;\r\n    font-family: -webkit-body;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
