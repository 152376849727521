// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .ag-header-cell {
  flex: 1;
} */
li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
  background-color: #17c2e8bb !important;
  color: black !important;
}
.ag-cell-focus,
.ag-cell {
  border: none !important;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.ag-header-cell {
  background-color: white;
  color: rgb(131, 146, 171);
  font-size: 0.75rem;
  font-weight: 700;
}

.ag-header {
  border: none !important;
  text-transform: uppercase;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-root-wrapper {
  border: none !important;
}

.ag-body-vertical-scroll {
  display: none;
  width: 0;
}

.ag-cell.ag-cell-checkbox-input-wrapper {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/examples/TableAll/index.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;EACE,sCAAsC;EACtC,uBAAuB;AACzB;AACA;;EAEE,uBAAuB;EACvB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* .ag-header-cell {\r\n  flex: 1;\r\n} */\r\nli.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {\r\n  background-color: #17c2e8bb !important;\r\n  color: black !important;\r\n}\r\n.ag-cell-focus,\r\n.ag-cell {\r\n  border: none !important;\r\n  display: flex;\r\n  height: 100%;\r\n  width: 100%;\r\n  align-items: center;\r\n}\r\n\r\n.ag-header-cell {\r\n  background-color: white;\r\n  color: rgb(131, 146, 171);\r\n  font-size: 0.75rem;\r\n  font-weight: 700;\r\n}\r\n\r\n.ag-header {\r\n  border: none !important;\r\n  text-transform: uppercase;\r\n}\r\n\r\n.ag-header-cell-label {\r\n  justify-content: center;\r\n}\r\n\r\n.ag-root-wrapper {\r\n  border: none !important;\r\n}\r\n\r\n.ag-body-vertical-scroll {\r\n  display: none;\r\n  width: 0;\r\n}\r\n\r\n.ag-cell.ag-cell-checkbox-input-wrapper {\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
