import React from "react";
// prop-types is a library for typechecking of props

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import "./index.css";
import PropTypes from "prop-types";
import { Dropdown, Button, message, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
// Add AG Grid
import { AgGridReact } from "ag-grid-react";
import SuiBadge from "components/SuiBadge";
import { getBadgeContent } from "examples/QueuedTable/Utils";
import { getBadgeColor } from "examples/QueuedTable/Utils";
import { formatDateWithTime } from "./Utils";
import { numericComparator } from "examples/QueuedTable/Utils";
import { dateComparator } from "examples/QueuedTable/Utils";
import { currencyComparator } from "examples/QueuedTable/Utils";
import { useEffect, useRef, useState, useMemo, forwardRef, useImperativeHandle } from "react";
// import { ContactPageSharp } from "@mui/icons-material";
import { Checkbox } from "antd";
//import TicketsApi
import TicketsApi from "api/tickets";
// Cenll Render
const FeedbackStatusCellRenderer = (params) => {
  const {
    column: { colId },
    data,
  } = params;
  return (
    <SuiBox key={data.uuid} p={1}>
      <SuiBadge
        variant="gradient"
        badgeContent={getBadgeContent(colId, data)}
        color={getBadgeColor(colId, data)}
        size="extra-small"
      />
    </SuiBox>
  );
};
const ReservedAtCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{formatDateWithTime(data.reserved_at)}</SuiBox>;
};
const EventNameCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.event_name}</SuiBox>;
};
const EventDateCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.event_date}</SuiBox>;
};
const TicketQuantityCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.ticket_quantity}</SuiBox>;
};
const ApprovedByCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.approved_by}</SuiBox>;
};
const TotalPriceCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.ticket_price}</SuiBox>;
};
const SeatRowCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.seat_row}</SuiBox>;
};
const SetaNumberCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.seat_numbers}</SuiBox>;
};
const SeatSectionCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.seat_section}</SuiBox>;
};
const WorkerEmailCellRenderer = (params) => {
  const { data } = params;
  return <SuiBox>{data.worker_email}</SuiBox>;
};

// +====const moment = require("moment");============================
const ticketQuantityComparator = (number1, number2) => {
  if (parseInt(number1, 10) === parseInt(number2, 10)) {
    return 0;
  } else {
    return parseInt(number1, 10) > parseInt(number2, 10) ? 1 : -1;
  }
};

// const eventTimeStapmComparator = (timestamp) => {
//   if (!timestamp) return "-";
//   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   const date = momentTZ.utc(timestamp).tz(timezone);
//   // Get the weekday, day, month, and year components of the date
//   const weekday = date.format("ddd"); // Abbreviated weekday name
//   const day = date.format("D"); // Day of the month
//   const month = date.format("MMMM"); // Month name
//   const year = date.format("YYYY"); // Year

//   // Get the hour and minute components of the time
//   const hour = date.format("h"); // Hour in 12-hour format
//   const minute = date.format("mm"); // Minute
//   const ampm = date.format("A"); // AM/PM indicator

//   // Construct the formatted date string
//   const formattedDate = `${weekday} • ${month} ${day}, ${year} • ${hour}:${minute} ${ampm}`;

//   return formattedDate; // Output: Tue • March 13, 2023 • 7:30 PM
// };

const CustomCheckboxFilter = forwardRef((props, ref) => {
  const [checkedValues, setCheckedValues] = useState(new Set());
  const checkedValuesRef = useRef(checkedValues);

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    const newCheckedValues = new Set(checkedValues);

    if (e.target.checked) {
      newCheckedValues.add(value);
    } else {
      newCheckedValues.delete(value);
    }

    // Update state and ref
    setCheckedValues(newCheckedValues);
    checkedValuesRef.current = newCheckedValues;

    // Notify Ag-Grid to reapply the filter
    props.filterChangedCallback();
  };

  useImperativeHandle(ref, () => ({
    doesFilterPass: (params) => {
      return checkedValuesRef.current.has(params.data[props.colDef.field]);
    },
    isFilterActive: () => {
      return checkedValuesRef.current.size > 0;
    },
    getModel: () => {
      return Array.from(checkedValuesRef.current);
    },
    setModel: (model) => {
      const newSet = new Set(model);
      setCheckedValues(newSet);
      checkedValuesRef.current = newSet;
    },
  }));

  return (
    <div>
      {props.columnValues.map((value, index) => (
        <div key={index}>
          <Checkbox
            value={value}
            checked={checkedValues.has(value)}
            onChange={handleCheckboxChange}
          >
            {value}
          </Checkbox>
        </div>
      ))}
    </div>
  );
});

CustomCheckboxFilter.propTypes = {
  columnValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterChangedCallback: PropTypes.func.isRequired,
  colDef: PropTypes.shape({
    field: PropTypes.string.isRequired,
    filterParams: PropTypes.object,
  }).isRequired,
};
CustomCheckboxFilter.displayName = "CustomCheckboxFilter";
const CategoryDropdown = (params) => {
  const { ticket, setRowsDB } = params;
  //access setrowsdb from params

  const handleCategoryChange = (category_chosen) => {
    message.info("Updating Category...");
    console.log("click", category_chosen);
    console.log(ticket);
    //update rowdata
    ticket.category = category_chosen;
    ticket.workerEmail = ticket.worker_email;
    ticket.ce_ID = ticket.ce_id;
    TicketsApi.update_ticket(ticket)
      .then((res) => {
        console.log(res);
        //update ticket in rowsDb and setRowsDB
        setRowsDB((prev) => {
          return prev.map((item) => {
            if (item.uuid === ticket.uuid) {
              return ticket;
            } else {
              return item;
            }
          });
        });
      })
      .catch((err) => {
        console.error("Error updating ticket:", err);
      })
      .finally(() => {
        message.success("Category Updated");
      });
  };

  const items = [
    {
      key: "1-1",
      label: "Postsale",
      onClick: () => {
        handleCategoryChange("Postsale");
      },
    },
    {
      key: "1-2",
      label: "Onsale",
      onClick: () => {
        handleCategoryChange("Onsale");
      },
    },
    {
      key: "1-3",
      label: "Drop",
      onClick: () => {
        handleCategoryChange("Drop");
      },
    },
  ];
  return (
    <Dropdown menu={{ items }}>
      <Button>
        <Space>
          {ticket.category ? ticket.category : "Category..."}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
const PullerNameDropdown = (params) => {
  const { ticket, setRowsDB, puller_name_list } = params;
  //access setrowsdb from params
  useEffect(() => {
    // Your logic that updates puller_name_list here
    // Make sure to use setPullerNameList to update it
  }, [puller_name_list]);
  const handle_Puller_name_Change = (puller_name_chosen) => {
    message.info("Updating puller_name...");
    console.log("click", puller_name_chosen);
    console.log(ticket);
    //update rowdata
    ticket.puller_name = puller_name_chosen;
    TicketsApi.update_ticket(ticket)
      .then((res) => {
        console.log(res);
        //update ticket in rowsDb and setRowsDB
        setRowsDB((prev) => {
          return prev.map((item) => {
            if (item.uuid === ticket.uuid) {
              return ticket;
            } else {
              return item;
            }
          });
        });
      })
      .catch((err) => {
        console.error("Error updating ticket:", err);
      })
      .finally(() => {
        message.success("Category Updated");
      });
  };

  const items = puller_name_list.map((item, index) => ({
    key: index.toString(), // Convert index to string to ensure unique keys
    label: item.name,
    onClick: () => {
      handle_Puller_name_Change(item.name);
    },
  }));

  return (
    <Dropdown menu={{ items }}>
      <Button>
        <Space>
          {ticket.puller_name ? ticket.puller_name : "Puller..."}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
const Tag_Name_Dropdown = (params) => {
  const { ticket, setRowsDB, puller_tag_list } = params;
  //access setrowsdb from params
  const handle_Tag_name_Change = (tag_chosen) => {
    message.info("Updating Tag Name...");
    console.log("click", tag_chosen);
    console.log(ticket);
    //update rowdata
    ticket.tag_name = tag_chosen;
    TicketsApi.update_ticket(ticket)
      .then((res) => {
        console.log(res);
        //update ticket in rowsDb and setRowsDB
        setRowsDB((prev) => {
          return prev.map((item) => {
            if (item.uuid === ticket.uuid) {
              return ticket;
            } else {
              return item;
            }
          });
        });
      })
      .catch((err) => {
        console.error("Error updating ticket:", err);
      })
      .finally(() => {
        message.success("Category Updated");
      });
  };

  const items = puller_tag_list.map((item, index) => ({
    key: index.toString(), // Convert index to string to ensure unique keys
    label: item.name,
    onClick: () => {
      handle_Tag_name_Change(item.name);
    },
  }));

  return (
    <Dropdown menu={{ items }}>
      <Button>
        <Space>
          {ticket.tag_name ? ticket.tag_name : "Tag..."}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
const Table = ({ rowsDB, setRowsDB, settableRef, puller_name_list, puller_tag_list, gridApi }) => {
  const [columns, setColumns] = useState([]);
  // AG Grid Table
  const width = JSON.parse(localStorage.getItem("all_table_columns_with_widths"));
  const getColumnWidth = (cName) => {
    const filteredObject = width.find((e) => e.colId === cName);
    const widthValue = filteredObject ? filteredObject.width : null;
    return widthValue;
  };
  const columnDefs = [
    {
      field: "approved_by",
      headerName: "Approved by",
      sortable: true,
      cellRenderer: ApprovedByCellRenderer,
      width: width ? getColumnWidth("approved_by") : 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "worker_action_status",
      headerName: "worker action status",
      sortable: true,
      cellRenderer: FeedbackStatusCellRenderer,
      width: width ? getColumnWidth("worker_action_status") : 200,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "reserved_at",
      headerName: "Reserved at",
      cellRenderer: ReservedAtCellRenderer,
      comparator: numericComparator,
      cellStyle: { textAlign: "left" },
      sortable: true,
      width: width ? getColumnWidth("reserved_at") : "",
    },
    {
      field: "event_name",
      headerName: "Event name",
      sortable: true,
      filter: CustomCheckboxFilter,
      filterParams: {
        columnValues: [...new Set(rowsDB.map((row) => row.event_name))],
      },
      cellRenderer: EventNameCellRenderer,
      cellStyle: { textAlign: "left" },
      width: width ? getColumnWidth("event_name") : 300,
    },
    {
      field: "event_date",
      headerName: "event date",
      sortable: true,
      cellRenderer: EventDateCellRenderer,
      width: width ? getColumnWidth("event_date") : 300,
      comparator: dateComparator,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "ticket_quantity",
      headerName: "ticket quantity",
      sortable: true,
      cellRenderer: TicketQuantityCellRenderer,
      comparator: ticketQuantityComparator,
      width: width ? getColumnWidth("ticket_quantity") : 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "ticket_price",
      headerName: "ticket price",
      sortable: true,
      cellRenderer: TotalPriceCellRenderer,
      comparator: currencyComparator,
      width: width ? getColumnWidth("ticket_price") : 120,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "seat_row",
      headerName: "seat row",
      sortable: true,
      cellRenderer: SeatRowCellRenderer,
      comparator: numericComparator,
      width: width ? getColumnWidth("seat_row") : 150,
      tooltipField: "seat_row",
      cellStyle: { textAlign: "center" },
    },
    {
      field: "seat_numbers",
      headerName: "seat numbers",
      sortable: true,
      cellRenderer: SetaNumberCellRenderer,
      tooltipField: "seat_numbers",
      width: width ? getColumnWidth("seat_numbers") : "",
      cellStyle: { textAlign: "center" },
    },
    {
      field: "seat_section",
      headerName: "seat section",
      sortable: true,
      cellRenderer: SeatSectionCellRenderer,
      width: width ? getColumnWidth("seat_section") : 150,
      tooltipField: "seat section",
      cellStyle: { textAlign: "center" },
    },
    {
      field: "worker_email",
      headerName: "worker email",
      sortable: true,
      cellRenderer: WorkerEmailCellRenderer,
      tooltipField: "worker_email",
      cellStyle: { textAlign: "center" },
      width: width ? getColumnWidth("worker_email") : 250,
    },
    {
      field: "tag_name",
      headerName: "Tag",
      cellRenderer: Tag_Name_Dropdown,
      //add set rowsdb as param
      cellRendererParams: (params) => {
        return { ticket: params.data, setRowsDB: setRowsDB, puller_tag_list: puller_tag_list };
      },
      tooltipField: "tag_name",
      filter: CustomCheckboxFilter,
      filterParams: {
        columnValues: [...new Set(rowsDB.map((row) => row.tag_name))],
      },
      cellClass: "center-cell",
      cellStyle: { textAlign: "center" },
      width: width ? "132px" : "132px",
    },
    {
      field: "category",
      headerName: "Category",
      cellRenderer: CategoryDropdown,
      //add set rowsdb as param
      cellRendererParams: (params) => {
        return { ticket: params.data, setRowsDB: setRowsDB };
      },
      // valueFormatter: (params) => params.data.category,
      tooltipField: "category",
      filter: CustomCheckboxFilter,
      filterParams: {
        columnValues: [...new Set(rowsDB.map((row) => row.category))],
      },
      cellClass: "center-cell",
      cellStyle: { textAlign: "center" },
      width: width ? "132px" : "132px",
    },
    {
      field: "puller_name",
      headerName: "Puller Name",
      cellRenderer: PullerNameDropdown,
      //add set rowsdb as param
      cellRendererParams: (params) => {
        return { ticket: params.data, setRowsDB: setRowsDB, puller_name_list: puller_name_list };
      },
      filter: CustomCheckboxFilter,
      filterParams: {
        columnValues: [...new Set(rowsDB.map((row) => row.puller_name))],
      },
      tooltipField: "puller_name",
      cellClass: "center-cell",
      cellStyle: { textAlign: "center" },
      width: width ? "132px" : "132px",
    },
    {
      field: "feedback_status",
      headerName: "Feedback status",
      cellRenderer: FeedbackStatusCellRenderer,
      sortable: true,
      width: width ? getColumnWidth("feedback_status") : 160,
      cellStyle: { textAlign: "center" },
    },
  ];
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
  }));
  // =================================================================
  const columnResize = (params) => {
    const columnWidths = params.columnApi.getColumnState().map((c) => ({
      colId: c.colId,
      width: c.width,
    }));
    localStorage.setItem("all_table_columns_with_widths", JSON.stringify(columnWidths));
  };
  const LS_columns_with_widths = JSON.parse(localStorage.getItem("all_table_columns_with_widths"));

  useEffect(() => {
    if (LS_columns_with_widths?.length > 0) {
      const newColumn = ["approved_by"];
      LS_columns_with_widths?.map((j) => {
        return columnDefs.map((i) => {
          if ((i.field == "" ? "0" : i.field) == j.colId) {
            newColumn.push(i);
          }
        });
      });
      setColumns(columnDefs);
    } else {
      setColumns(columnDefs);
    }
    // console.log("🎈�� useEffect");
    // console.log(gridRef.current);
    // if (gridRef.current.props.rowData?.length > 0) {
    //   console.log("Many columns");
    //   //gridRef.current.api.setRowData(rowsDB);
    //   var filterInstance = gridRef.current.api.getFilterInstance("event_name");
    //   console.log(filterInstance);
    //   filterInstance.resetFilterValues();
    // }
  }, [rowsDB]);
  const gridOptions = {
    suppressColumnVirtualisation: true,
    floatingFilter: true,
  };
  const onGridReady = (params) => {
    settableRef(params.api);
    console.log("🎈 table ready");
  };

  return (
    <SuiBox style={{ height: "inherit" }}>
      {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
      <SuiBox
        className="ag-theme-alpine"
        style={{
          width: "100%",
          maxHeight: "80vh",
          height: "calc(100vh - 270px)",
        }}
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={columns}
          rowData={rowsDB}
          onGridReady={onGridReady}
          ref={gridApi}
          rowSelection="multiple" // Options - allows click selection of rows
          animateRows={true}
          getRowHeight={() => 65}
          gridOptions={gridOptions}
          suppressDragLeaveHidesColumns={true}
          onColumnResized={columnResize}
          onColumnMoved={columnResize}
          paginationPageSize={50}
          pagination={true}
        />
      </SuiBox>
    </SuiBox>
  );
};

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rowsDB: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rowsDB: PropTypes.arrayOf(PropTypes.object),
  setRowsDB: PropTypes.func,
  gridApi: PropTypes.object,
  settableRef: PropTypes.func,
  puller_name_list: PropTypes.arrayOf(PropTypes.object),
  puller_tag_list: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(Table);
