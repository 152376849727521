import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
// import { API_SERVER } from "./config/constant";

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";
import { getMessaging, getToken } from "firebase/messaging";
import { AuthProvider } from "auth-context/auth.context";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//const axios = require("axios");
let user = localStorage.getItem("user");
user = JSON.parse(user);

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCncaDPcvasMfpYKkyZxfxsngXDQRuOlsk",
  authDomain: "ticket-relay-notifications.firebaseapp.com",
  projectId: "ticket-relay-notifications",
  storageBucket: "ticket-relay-notifications.appspot.com",
  messagingSenderId: "26794957278",
  appId: "1:26794957278:web:91267409b210614099654f",
};
// function send_new_token_to_backend(token, user_id) {
//   const data = {
//     token: token,
//     user_id: user_id,
//   };

//   axios
//     .post(API_SERVER + "users/addNewPushUser", data)
//     .then((response) => {
//       console.log(response.data);
//       window.localStorage.setItem("token_saved_in_backend", true);
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// }
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
function requestPermission() {
  console.log("Requesting permission...");
  localStorage.removeItem("token_saved_in_backend");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
    }
  });
}
getToken(messaging, {
  vapidKey:
    "BNtYj_YEsjpaF49O08Teerwk8s1NE6y0gSPpPgX9JIq_Wq76LnegRsJ7EbiziaTji_ebOOV1iAISTbgYKYMCLtc",
})
  .then(() => {
    // Show permission request UI
    console.log("No registration token available. Request permission to generate one.");
    requestPermission();
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token.", err);
  });

navigator.serviceWorker
  .register("./firebase-messaging-sw.js", { scope: "/" })
  .then(function (registration) {
    // Registration was successful
    console.log("ServiceWorker registration successful with scope: ", registration.scope);
    navigator.serviceWorker.onmessage = (event) => {
      console.log("✔️ Received message from APP:", event);
      // Do something with the message data
      const title = event.data.head;
      const options = {
        body: event.data.body,
        // icon: event.data.icon,
      };
      registration.showNotification(title, options);
    };
  });
ReactDOM.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <AuthProvider userData={user}>
        <App />
      </AuthProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
