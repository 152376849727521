import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { message } from "antd";
import TicketsApi from "api/tickets";
import PropTypes from "prop-types";

function Bulk_Action_Dropdown({ puller_name_list, setRowsDB, rowsDB, tag_list, gridApi }) {
  const [disabled, setDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadings, setLoadings] = useState(false);
  function update_all_tickets_changes(selectedRows) {
    // Make API calls to update each ticket
    console.log("update_all_tickets_changes", selectedRows.current);
    const updatePromises = selectedRows.current.map((row) => {
      console.log("Calling API to update ticket with id: " + row.uuid);
      return TicketsApi.update_ticket(row)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error("Error updating ticket:", err);
        });
    });

    Promise.all(updatePromises).finally(() => {
      //change rowsDB state, for each selected row
      let rowsDBCopy = [...rowsDB];
      selectedRows.current.forEach((row) => {
        rowsDBCopy.forEach((rowDB) => {
          if (rowDB.uuid == row.uuid) {
            rowDB = row;
          }
        });
      });
      setRowsDB(rowsDBCopy);
      message.success("Selected Tickets Updated");
      setLoadings(false);
      setDisabled(true);
    });
  }
  let selectedRowsRef = useRef(selectedRows);
  useEffect(() => {
    const onSelectionChanged = () => {
      setTimeout(() => {
        var selectedRowsNew = gridApi.current.api.getSelectedRows();
        if (selectedRowsNew.length != 0) {
          setSelectedRows(selectedRowsNew);
        } else setDisabled(true);
      }, 100);
    };
    //check if more than 0 length
    document.querySelector(".ag-theme-alpine").addEventListener("click", onSelectionChanged);
  }, []);
  useEffect(() => {
    selectedRowsRef.current = selectedRows;
    if (selectedRows.length > 0) {
      //enable dropdown
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedRows]);

  const handleCategoryClick = (label) => {
    message.info("Updating Category to " + label + "...");
    setLoadings(true); // Assuming this function is defined elsewhere

    // Update category field for all selected rows
    selectedRowsRef.current.forEach((row) => {
      row.category = label;
    });

    // Make API calls to update each ticket
    update_all_tickets_changes(selectedRowsRef);
  };
  const handlePullerNameClick = (chosen_name) => {
    message.info("Updating Puller Name to Manager");
    console.log("click on Puller Name:", chosen_name);
    setLoadings(true); // Assuming this function is defined elsewhere

    // Update category field for all selected rows
    selectedRowsRef.current.forEach((row) => {
      row.puller_name = chosen_name;
    });
    update_all_tickets_changes(selectedRowsRef);
  };
  const handleTagNameClick = (chosen_name) => {
    message.info("Updating Tag Names...");
    console.log("click on Tag Name:", chosen_name);
    setLoadings(true); // Assuming this function is defined elsewhere

    // Update category field for all selected rows
    selectedRowsRef.current.forEach((row) => {
      row.tag_name = chosen_name;
    });
    update_all_tickets_changes(selectedRowsRef);
  };
  const handleMenuClick = (e) => {
    console.log("click", e);
  };
  const items = [
    {
      key: "1",
      label: "Category",
      children: [
        {
          key: "1-1",
          label: "Postsale",
          onClick: () => {
            handleCategoryClick("Postsale");
          },
        },
        {
          key: "1-2",
          label: "Onsale",
          onClick: () => {
            handleCategoryClick("Onsale");
          },
        },
        {
          key: "1-3",
          label: "Drop",
          onClick: () => {
            handleCategoryClick("Drop");
          },
        },
      ],
    },
    {
      key: "2",
      label: "Tag Name",
      children: tag_list.map((item, index) => ({
        key: `2-${index + 1}`,
        label: item.name,
        onClick: () => {
          handleTagNameClick(item.name);
        },
      })),
    },
    {
      key: "3",
      label: "Puller Name",
      children: puller_name_list.map((item, index) => ({
        key: `3-${index + 1}`,
        label: item.name,
        onClick: () => {
          handlePullerNameClick(item.name);
        },
      })),
    },
  ];
  return (
    <Dropdown menu={{ items }} disabled={disabled} onClick={handleMenuClick}>
      <Button loading={loadings}>
        <Space>
          Bulk Actions <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
Bulk_Action_Dropdown.propTypes = {
  setRowsDB: PropTypes.func,
  puller_name_list: PropTypes.array,
  rowsDB: PropTypes.array,
  tag_list: PropTypes.array,
  gridApi: PropTypes.object,
};
export default Bulk_Action_Dropdown;
