import { useEffect, useMemo, useState, useRef } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";
import "chart.js/auto";
// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// DefaultLineChart configurations
import configs from "examples/Charts/LineCharts/DefaultLineChart/configs";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
function DefaultLineChart({
  title,
  description,
  height,
  chart,
  customizeReportBy,
  customizeReportByIdentifier,
}) {
  let [chartDataset, setChartDatasets] = useState(undefined);
  let [options, setOptions] = useState(undefined);
  function updateChart(data) {
    let correct_dataset = "purchased_reservations_array";
    let category =
      customizeReportBy == "Total Reservations" ? "all" : customizeReportBy.toLowerCase();
    if (customizeReportBy == "Total Reservations") {
      correct_dataset = "purchased_reservations_array";
    }
    // else if (customizeReportBy == "Total Tickets Reserved") {
    //   correct_dataset = "data_tickets_reserved";
    // } else if (customizeReportBy == "Total Tickets Purchased") {
    //   correct_dataset = "data_tickets_purchased";
    // }
    console.log("📈customizeReportBy useEffect", category);
    console.log(data);
    console.log("customizeReportByIdentifier: ", customizeReportByIdentifier);
    //for each item in data add extra field data.data
    if (data.datasets[0].label == "My First Dataset") setChartDatasets(data);
    else if (customizeReportBy && customizeReportByIdentifier == "Tag Name") {
      console.log("by tag name");
      data.datasets.forEach((item) => {
        item.data = item.data ? item.data : [];
        item[correct_dataset].forEach((categorized_tot_ticket) => {
          item.data.push(categorized_tot_ticket["all_reservations_filtered"][category]);
        });
      });
      console.log("📈📈data chart for tag name", data);
      setChartDatasets(data);
    } else if (customizeReportBy && customizeReportByIdentifier == "Puller Name") {
      data.by_category_dataset.datasets.forEach((item) => {
        item.data = item.data ? item.data : [];
        item[correct_dataset].forEach((categorized_tot_ticket) => {
          item.data.push(categorized_tot_ticket["all_reservations_filtered"][category]);
        });
      });
      // // console.log("data chart for Category", data.by_category_dataset);
      setChartDatasets(data.by_category_dataset);
    } else setChartDatasets(data);
    // // console.log("chartDatasets", data);
  }

  const prevChart = useRef(chart);
  let prevCustomizeReportBy = useRef(customizeReportBy);
  let chart_data = useRef({});

  useEffect(() => {
    if (
      JSON.stringify(prevChart.current) !== JSON.stringify(chart.datasets) ||
      customizeReportBy !== prevCustomizeReportBy.current
    ) {
      // // console.log("⬇️ Chart changed... setting data for line chart");
      // // console.log("chart", chart);
      prevCustomizeReportBy.current = customizeReportBy;
      let color_detailed_dataset = chart.datasets.map((dataset) => ({
        ...dataset,
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 2,
        pointBackgroundColor: colors[dataset.color]?.main
          ? colors[dataset.color]?.main
          : dataset.color,
        borderColor: colors[dataset.color]?.main ? colors[dataset.color].main : dataset.color,
        maxBarThickness: 6,
      }));
      // console.log("🆗 color_detailed_dataset", color_detailed_dataset);
      setChartDatasets(color_detailed_dataset);
      const { data, options } = configs(chart.labels, color_detailed_dataset, customizeReportBy);
      if (chart.by_category_dataset) {
        // // console.log("🆗 chart.by_category_datasets", chart.by_category_dataset);
        data.by_category_dataset = {};
        data.by_category_dataset.datasets = chart.by_category_dataset.datasets.map((dataset) => ({
          ...dataset,
          tension: 0.4,
          borderWidth: 3,
          pointRadius: 2,
          pointBackgroundColor: colors[dataset.color]?.main
            ? colors[dataset.color]?.main
            : dataset.color,
          borderColor: colors[dataset.color]?.main ? colors[dataset.color].main : dataset.color,
          maxBarThickness: 6,
        }));
        data.by_category_dataset.labels = chart.by_category_dataset.labels;
      }
      // console.log("🌍 data", data);
      chart_data.current = data;
      setOptions(options);
      // // console.log("chart is different than before the last state update");
      updateChart(data);
      prevChart.current = chart.datasets;
    }
  }, [chart, customizeReportBy]);
  useEffect(() => {
    // // console.log("customizeReportByIdentifier useEffect", customizeReportByIdentifier);
    // // console.log("⭐ useEffect", chart_data.current);
    updateChart(chart_data.current);
  }, [customizeReportByIdentifier]);

  const renderChart = (
    <SuiBox p={2}>
      {title || description ? (
        <SuiBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SuiBox mb={1}>
              <SuiTypography variant="h6">{title}</SuiTypography>
            </SuiBox>
          )}
          <SuiBox mb={2}>
            <SuiTypography variant="button" fontWeight="regular" textColor="text">
              {description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      ) : null}
      {useMemo(
        () => (
          <SuiBox height={height}>
            <Line data={chartDataset} options={options} />
          </SuiBox>
        ),
        [chartDataset, height]
      )}
    </SuiBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of DefaultLineChart
DefaultLineChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the DefaultLineChart
DefaultLineChart.propTypes = {
  title: PropTypes.string,
  customizeReportBy: PropTypes.string,
  customizeReportByIdentifier: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.object.isRequired,
};

export default DefaultLineChart;
