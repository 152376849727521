const moment = require("moment-timezone"); // If you're using Node.js or CommonJS
// import moment from 'moment';  // If you're using ES6 modules

export const get_LineChart_data = (rowsDB) => {
  const generateDatasets_by_tagName = (rowsDB, labels) => {
    const uniqueTags = [...new Set(rowsDB.map((row) => row.tag_name))];
    const uniquePullerNames = [...new Set(rowsDB.map((row) => row.puller_name))];
    const generateRandomColor = () => "#" + Math.floor(Math.random() * 16777215).toString(16);
    const colors = uniqueTags.map(() => generateRandomColor());
    const colorsPullerName = uniquePullerNames.map(() => generateRandomColor());
    // Initialize data structures
    let tagData = {};
    uniqueTags.forEach((tag) => {
      tagData[tag] = {
        reservations_purchased_filtered: { all: 0, onsale: 0, postsale: 0, drop: 0 },
        all_reservations_filtered: { all: 0, onsale: 0, postsale: 0, drop: 0 },
        data_filtered_by_date: labels.map(() => ({
          reservations_purchased_filtered: { all: 0, onsale: 0, postsale: 0, drop: 0 },
          all_reservations_filtered: { all: 0, onsale: 0, postsale: 0, drop: 0 },
        })),
      };
    });
    let puller_name_data = {};
    uniquePullerNames.forEach((puller_name) => {
      puller_name_data[puller_name] = {
        reservations_purchased_filtered: { all: 0, onsale: 0, postsale: 0, drop: 0 },
        all_reservations_filtered: { all: 0, onsale: 0, postsale: 0, drop: 0 },
        data_filtered_by_date: labels.map(() => ({
          reservations_purchased_filtered: { all: 0, onsale: 0, postsale: 0, drop: 0 },
          all_reservations_filtered: { all: 0, onsale: 0, postsale: 0, drop: 0 },
        })),
      };
    });

    // Process rowsDB in a single pass
    rowsDB.forEach((row) => {
      const tag = row.tag_name;
      const category = row.category;
      const puller_name = row.puller_name;
      const isPurchased = row.worker_action_status === "P";
      const rowTime = parseInt(row.reserved_at);

      labels.forEach((label, index) => {
        const endOfLabelTime = moment(label, "MM-DD h:m A").tz("America/Los_Angeles").valueOf();
        let startTime =
          index === 0
            ? 0
            : moment(labels[index - 1], "MM-DD h:m A")
                .tz("America/Los_Angeles")
                .valueOf();
        if (rowTime <= endOfLabelTime && rowTime > startTime) {
          if (isPurchased) {
            tagData[tag].data_filtered_by_date[index].reservations_purchased_filtered.all++;
            puller_name_data[puller_name].data_filtered_by_date[index]
              .reservations_purchased_filtered.all++;
            category &&
              tagData[tag].data_filtered_by_date[index].reservations_purchased_filtered[
                category.toLowerCase()
              ]++ &&
              puller_name_data[puller_name].data_filtered_by_date[index]
                .reservations_purchased_filtered[category.toLowerCase()]++;
          }
          tagData[tag].data_filtered_by_date[index].all_reservations_filtered.all++;
          puller_name_data[puller_name].data_filtered_by_date[index].all_reservations_filtered
            .all++;
          category &&
            tagData[tag].data_filtered_by_date[index].all_reservations_filtered[
              category.toLowerCase()
            ]++ &&
            puller_name_data[puller_name].data_filtered_by_date[index].all_reservations_filtered[
              category.toLowerCase()
            ]++;
        }
      });
    });

    // Generate datasets
    let datasets = uniqueTags.map((tag, index) => {
      let total_purchase_by_category = {
        all: 0,
        onsale: 0,
        postsale: 0,
        drop: 0,
      };
      let total_reservations = {
        all: 0,
        onsale: 0,
        postsale: 0,
        drop: 0,
      };

      tagData[tag].data_filtered_by_date.forEach((by_timePlot) => {
        Object.keys(by_timePlot.reservations_purchased_filtered).forEach((key) => {
          total_purchase_by_category[key] += by_timePlot.reservations_purchased_filtered[key];
          total_reservations[key] += by_timePlot.all_reservations_filtered[key];
        });
      });

      return {
        label: tag,
        color: colors[index],
        data_total_reservations: total_reservations,
        purchased_reservations_array: tagData[tag].data_filtered_by_date,
        total_purchased_reservations: total_purchase_by_category,
      };
    });
    let datasets_pullerName = uniquePullerNames.map((pullerName, index) => {
      let total_purchase_by_category = {
        all: 0,
        onsale: 0,
        postsale: 0,
        drop: 0,
      };
      let total_reservations = {
        all: 0,
        onsale: 0,
        postsale: 0,
        drop: 0,
      };
      puller_name_data[pullerName].data_filtered_by_date.forEach((by_timePlot) => {
        Object.keys(by_timePlot.reservations_purchased_filtered).forEach((key) => {
          total_purchase_by_category[key] += by_timePlot.reservations_purchased_filtered[key];
          total_reservations[key] += by_timePlot.all_reservations_filtered[key];
        });
      });

      return {
        label: pullerName == null ? "N/A" : pullerName,
        color: colorsPullerName[index],
        data_total_reservations: total_reservations,
        purchased_reservations_array: puller_name_data[pullerName].data_filtered_by_date,
        total_purchased_reservations: total_purchase_by_category,
      };
    });
    datasets.sort((a, b) => b.data_total_reservations.all - a.data_total_reservations.all);
    datasets_pullerName.sort(
      (a, b) => b.data_total_reservations.all - a.data_total_reservations.all
    );
    let puller_data_organized = {
      datasets: datasets_pullerName,
    };
    return { datasets: datasets, labels: labels, puller_name_dataset: puller_data_organized };
  };

  // const generateCategoryDatasets = (rowsDB, labels) => {
  //   const uniqueCategories = [...new Set(rowsDB.map((row) => row.category))];

  //   const generateRandomColor = () => {
  //     return "#" + Math.floor(Math.random() * 16777215).toString(16);
  //   };
  //   const colors = Array.from({ length: uniqueCategories.length }, () => generateRandomColor());

  //   const calculateDataByCategory = (labelTime, category) => {
  //     const endOfLabelTime = moment(labelTime, "MM-DD h A").valueOf();
  //     const filteredRows = rowsDB.filter((row) => {
  //       const rowTime = parseInt(row.reserved_at);
  //       return row.category === category && rowTime <= endOfLabelTime;
  //     });
  //     return filteredRows.length;
  //   };

  //   const calculateTicketDataByCategory = (labelTime, category, condition) => {
  //     const endOfLabelTime = moment(labelTime, "MM-DD h:m A").tz("America/Los_Angeles").valueOf();
  //     const filteredRows = rowsDB.filter((row) => {
  //       const rowTime = parseInt(row.reserved_at);
  //       return row.category === category && rowTime <= endOfLabelTime && condition(row);
  //     });
  //     return filteredRows.reduce((sum, row) => sum + parseInt(row.ticket_quantity), 0);
  //   };

  //   let categoryDatasets = uniqueCategories.map((category, index) => {
  //     const data = labels.map((label) => calculateDataByCategory(label, category));
  //     const data_tickets_reserved = labels.map((label) =>
  //       calculateTicketDataByCategory(label, category, () => true)
  //     );
  //     const data_tickets_purchased = labels.map((label) =>
  //       calculateTicketDataByCategory(label, category, (row) => row.worker_action_status === "P")
  //     );

  //     return {
  //       label: category == null ? "N/A" : category,
  //       color: colors[index],
  //       data_total_reservations: data,
  //       total: data.reduce((sum, count) => sum + count, 0),
  //       total_tickets_reserved: data_tickets_reserved.reduce((sum, count) => sum + count, 0),
  //       total_tickets_purchased: data_tickets_purchased.reduce((sum, count) => sum + count, 0),
  //       data_tickets_reserved: data_tickets_reserved,
  //       data_tickets_purchased: data_tickets_purchased,
  //       percentage: Math.round(
  //         (data_tickets_purchased.reduce((sum, count) => sum + count, 0) /
  //           (data_tickets_reserved.reduce((sum, count) => sum + count, 0) || 1)) *
  //           100
  //       ),
  //     };
  //   });

  //   categoryDatasets.sort((a, b) => b.total - a.total);

  //   return { datasets: categoryDatasets, labels: labels };
  // };

  const filteredRows = rowsDB.filter(
    (row) => row.reserved_at !== null && row.reserved_at !== undefined
  );

  const sortedRows = filteredRows.sort((a, b) => parseInt(a.reserved_at) - parseInt(b.reserved_at));

  if (sortedRows.length === 0) {
    return [];
  }

  const earliestDate = parseInt(sortedRows[0].reserved_at);
  const latestDate = parseInt(sortedRows[sortedRows.length - 1].reserved_at);
  const step = (latestDate - earliestDate) / 6;
  const labels = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(earliestDate + step * i);
    return moment(date).tz("America/Los_Angeles").format("MM-DD - hA");
  });
  console.log("HERE we go");
  let dataset = generateDatasets_by_tagName(rowsDB, labels);
  dataset.puller_name_dataset.labels = dataset.labels;
  return dataset;
};

export const generate_bar_chart_Data = async (
  originalDatasets,
  customizeReportByActivity = "all"
) => {
  // console.log("generate_bar_chart_Data()");
  // console.log(customizeReportByActivity);
  originalDatasets.sort(
    (a, b) =>
      b.data_total_reservations[customizeReportByActivity.toLowerCase()] -
      a.data_total_reservations[customizeReportByActivity.toLowerCase()]
  );
  return new Promise((resolve) => {
    const data = {
      labels: originalDatasets.map((item) => (item.label == null ? "N/A" : item.label)),
      datasets: [
        {
          label: "Total Reservations",
          color: "dark",
          data: originalDatasets.map(
            (item) => item.data_total_reservations[customizeReportByActivity.toLowerCase()]
          ),
        },
        {
          label: "Total Reservations Purchased",
          color: "success",
          data: originalDatasets.map(
            (item) => item.total_purchased_reservations[customizeReportByActivity.toLowerCase()]
          ),
        },
        // {
        //   label: "Percentage Tickets Purchased",
        //   color: "success",
        //   data: originalDatasets.map((item) => item.percentage_tickets_purchased),
        // },
      ],
    };

    resolve(data);
  });
};

export const generateDoughnutData = (originalDatasets) => {
  let firstFiveData = originalDatasets.slice(0, 5);

  firstFiveData.forEach((item) => {
    item.total = item.data_total_reservations.reduce((a, b) => a + b, 0);
    item.total_tickets_reserved_sum = item.data_tickets_reserved.reduce((a, b) => a + b, 0);
    item.total_tickets_purchased_sum = item.data_tickets_purchased.reduce((a, b) => a + b, 0);
  });

  return {
    labels: firstFiveData.map((item) => item.label),
    datasets: [
      {
        label: "Total Reservations",
        backgroundColors: firstFiveData.map((item) => item.color),
        data: firstFiveData.map((item) => item.total),
      },
      {
        label: "Tickets Reserved",
        backgroundColors: firstFiveData.map((item) => item.color),
        data: firstFiveData.map((item) => item.total_tickets_reserved_sum),
      },
      {
        label: "Tickets Purchased",
        backgroundColors: firstFiveData.map((item) => item.color),
        data: firstFiveData.map((item) => item.total_tickets_purchased_sum),
      },
    ],
  };
};
