/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SuiBadge from "components/SuiBadge";
import FormDialog from "components/MuiDialog";
// Images

// function Author({ image, name, email }) {
//   return (
//     <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
//       <SuiBox mr={2}>
//         <SuiAvatar src={image} alt={name} size="sm" variant="rounded" />
//       </SuiBox>
//       <SuiBox display="flex" flexDirection="column">
//         <SuiTypography variant="button" fontWeight="medium">
//           {name}
//         </SuiTypography>
//         <SuiTypography variant="caption" textColor="secondary">
//           {email}
//         </SuiTypography>
//       </SuiBox>
//     </SuiBox>
//   );
// }

// function Function({ job, org }) {
//   return (
//     <SuiBox display="flex" flexDirection="column">
//       <SuiTypography variant="caption" fontWeight="medium" textColor="text">
//         {job}
//       </SuiTypography>
//       <SuiTypography variant="caption" textColor="secondary">
//         {org}
//       </SuiTypography>
//     </SuiBox>
//   );
// }

function formatDate(date) {
  let month_day = new Date(date).toLocaleDateString("en-us", { month: "short", day: "numeric" });
  let time = new Date(date).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return month_day + " - " + time;
}
export default {
  columns: [
    {
      name: "Feedback status",
      fromDB: "feedback_status",
      align: "center",
      type: "none",
      isSortable: false,
    },
    {
      name: "",
      fromDB: "checkBox",
      align: "left",
      type: "string",
      isSortable: false,
      width: "160px",
    },
    { name: "Timer", fromDB: "timer", align: "left", type: "string", isSortable: true },
    { name: "Cancel", fromDB: "cancel", align: "left", type: "none", isSortable: false },
    { name: "Purchase", fromDB: "purchase", align: "left", type: "none", isSortable: true },
    { name: "Reserved at", fromDB: "reserved_at", align: "left", type: "string", isSortable: true },
    {
      name: "Reseller Website",
      fromDB: "reseller_website",
      align: "left",
      type: "string",
      isSortable: true,
    },
    { name: "Event name", fromDB: "event_name", align: "left", type: "string", isSortable: true },
    { name: "Venue", fromDB: "venue", align: "left", type: "string", isSortable: true },
    { name: "Event date", fromDB: "event_date", align: "center", type: "date", isSortable: true },
    {
      name: "Ticket quantity",
      fromDB: "ticket_quantity",
      align: "center",
      type: "number",
      isSortable: true,
    },
    {
      name: "Total price",
      fromDB: "ticket_price",
      align: "center",
      type: "number",
      isSortable: true,
    },
    {
      name: "Seat section",
      fromDB: "seat_section",
      align: "center",
      type: "string",
      isSortable: true,
    },
    { name: "Seat row", fromDB: "seat_row", align: "center", type: "number", isSortable: true },
    {
      name: "Seat numbers",
      fromDB: "seat_numbers",
      align: "center",
      type: "string",
      isSortable: true,
    },
    {
      name: "Worker email",
      fromDB: "worker_email",
      align: "center",
      type: "string",
      isSortable: true,
    },
    {
      name: "tag_name",
      fromDB: "tag_name",
      align: "center",
      type: "string",
      isSortable: true,
    },
    { name: "Note", fromDB: "note", align: "center", type: "none", isSortable: false },
  ],
  getAllTickets: function (allTickets, setRows) {
    console.log("getAllTickets() \n\n", allTickets + "\n");
    return new Promise(function (resolve) {
      resolve(
        allTickets.map((singleTicketInfo) => {
          if (singleTicketInfo.feedback_status == "Q") {
            singleTicketInfo.feedback_class = "warning";
            singleTicketInfo.feedback_text = "Queued";
          } else if (singleTicketInfo.feedback_status == "A") {
            singleTicketInfo.feedback_class = "success";
            singleTicketInfo.feedback_text = "Approved";
          } else if (singleTicketInfo.feedback_status == "N") {
            singleTicketInfo.feedback_class = "secondary";
            singleTicketInfo.feedback_text = "Note";
          } else {
            singleTicketInfo.feedback_class = "error";
            singleTicketInfo.feedback_text = "Denied";
          }
          return {
            key: singleTicketInfo.id,
            Reserved_at: formatDate(singleTicketInfo.reserved_at),
            Event_name: singleTicketInfo.event_name,
            Event_date: singleTicketInfo.event_date,
            Ticket_quantity: singleTicketInfo.ticket_quantity,
            Seat_row: singleTicketInfo.seat_row,
            Seat_numbers: singleTicketInfo.seat_numbers,
            Seat_section: singleTicketInfo.seat_section,
            Worker_email: singleTicketInfo.worker_email,
            TAG: singleTicketInfo.tag_name,
            Feedback_status: (
              <SuiBadge
                variant="gradient"
                badgeContent={singleTicketInfo.feedback_text}
                color={singleTicketInfo.feedback_class}
                size="extra-small"
              />
            ),
            Note: (
              <FormDialog
                setrows={setRows}
                rows={allTickets}
                btn_text="Make a Decision"
                ticket_info={singleTicketInfo}
              />
            ),
          };
        })
      ).then((mappedTicket) => {
        resolve(mappedTicket);
      });
    });
  },
};
